.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
/* estilos personalizados */
.form-section-s {
	background-color: #fff;
	height: 100vh;
}
.nav-s {
	background: linear-gradient(180deg, rgba(18, 86, 179, 1) 0%, rgba(0, 119, 232, 1) 100%);
	display: block;
	text-align: center;
}
.container-s {
	margin-top: 50px;
	padding: 2% 5%;
	border-radius: 10px;
	background-color: #eee;
}
.label-name {
	font-size: 26px;
	color: #1256b3;
	text-align: left;
	font-weight: bold;
}
.button-s {
	font-size: 20px;
	color: white;
	text-align: center;
	margin: auto !important;
}
.label-name {
  font-size: 26px;
  color: #1256b3;
  text-align: left;
  font-weight: bold;
}
.btn-primary.disabled, .btn-primary:disabled {
  color: white;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary, .btn-primary {
  color: white;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.modal-content {
  background-color: #003077 !important;
  /* border: 1px solid #1c5bb952; */
  color: #fff;
  border-radius: 25px;
  text-align: center;
}

.modal-body {
	font-size: 22px;
	font-weight: 600;
}
.modal-header {
	font-size: 30px;
	font-weight: 600;
}
.modal {
	background-color: rgba(255, 255, 255, 0.322) !important;
}
.modal-header {
	border: 0px;
	display: block;
}
